import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './shared/services/local-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'seamfix';

  constructor(
    private translate: TranslateService,
    private localStorageService: LocalStorageService
  ) {
    this.translate.use(this.localStorageService.getLanguage());
  }
}
