import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { LoggedInUser } from '../../features/auth/models/logged-in-user.model';
import { LoggedInUserService } from '../../features/auth/services/logged-in-user.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardedGuard implements CanActivate {
  constructor(
    private loggedInUserService: LoggedInUserService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    return this.loggedInUserService.$loggedInUser.pipe(
      filter((user) => !!user),
      map((user) => this.checkAccess(user)),
      tap((isAllowed) => {
        if (!isAllowed) {
          this.router.navigate(['/onboarding']);
        }
      })
    );
  }

  private checkAccess(user: LoggedInUser | null): boolean {
    if (!user) {
      return true;
    }
    const isAdmin = this.loggedInUserService.isUserIsAdmin(user.role.id as number);
    const isOnboarded = this.loggedInUserService.isUserOnBoarded(user);
    return isAdmin || isOnboarded;
  }
}
