import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth-guard';
import { OnboardedGuard } from './core/guards/onboarded.guard';
import { OAuthCallbackComponent } from './features/oauth/components/oauth-callback.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { OnboardingLayoutComponent } from './layouts/onboarding-layout/onboarding-layout.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard, OnboardedGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard, OnboardedGuard],
    children: [
      {
        path: 'organization-management',
        loadChildren: () =>
          import('./features/organization-management/organization-management.module').then(
            (m) => m.OrganizationManagementModule
          ),
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./features/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'reports-management',
        loadChildren: () =>
          import('./features/reports/reports.module').then((m) => m.ReportsModule),
      },
      {
        path: 'audit-management',
        loadChildren: () =>
          import('./features/audit-management/audit-management.module').then(
            (m) => m.AuditManagementModule
          ),
      },
      {
        path: 'applications',
        loadChildren: () =>
          import('./features/applications/applications.module').then((m) => m.ApplicationsModule),
      },

      {
        path: 'profile-settings',
        loadChildren: () =>
          import('./features/profile/profile.module').then((m) => m.ProfileModule),
      },

      {
        path: 'settings',
        loadChildren: () =>
          import('./features/settings/settings.module').then((m) => m.SettingsModule),
      },

      {
        path: 'privilege-access',
        loadChildren: () =>
          import('./features/privilege-access/privilege-access.module').then(
            (m) => m.PrivilegeAccessModule
          ),
      },

      {
        path: 'support',
        loadChildren: () =>
          import('./features/support/support.module').then((m) => m.SupportModule),
      },
    ],
  },

  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [NoAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./features/auth/auth.module').then((m) => m.AuthModule),
      },
    ],
  },

  {
    path: 'onboarding',
    component: OnboardingLayoutComponent,
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
    ],
  },

  {
    path: 'oauth/callback',
    component: OAuthCallbackComponent,
    children: [],
  },

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
