import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrl: './oauth-callback.component.scss',
})
export class OAuthCallbackComponent implements OnInit {
  ngOnInit(): void {
    const params = window.location.search;
    if (window.opener) {
      // send them to the opening window
      window.opener.postMessage(params, '*');
      // close the popup
      window.close();
    }
  }
}
