import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../../features/auth/services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toastr: ToastrService,
    private authService: AuthService,
    private translateService: TranslateService,
    private readonly injector: Injector
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //Ignores requests for token
        if (req.url.includes(this.authService.getTokenUrl() as string)) {
          return throwError(() => new Error(error.error.message));
        }
        let errorMessage;
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status} \nMessage: ${error.error.message}`;
        }

        if (error.status === HttpStatusCode.Unauthorized) {
          const refreshToken = this.authService.getRefreshToken();
          if (refreshToken) {
            return this.handleRefreshToken(req, next, refreshToken);
          }
        } else {
          this.toastr.error(this.translateService.instant(errorMessage));
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }

  handleRefreshToken(req: HttpRequest<unknown>, next: HttpHandler, refreshToken: string) {
    return this.authService.refreshToken(refreshToken).pipe(
      catchError((error) => {
        this.toastr.error(this.translateService.instant('toastr.sessionExpired'));
        this.authService.logOut();
        return throwError(() => new Error(error));
      }),
      switchMap((res) => {
        this.authService.storeAuthTokens(res);

        const clonedRequest = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getJwtToken()}`,
          },
        });

        return next.handle(clonedRequest);
      })
    );
  }
}
